import { CellStatusType } from '@chaos/types';
import { AlertSeverity, AlertType } from './generated';

export const alertSeverityStatus: Record<AlertSeverity, CellStatusType> = {
  HIGH: 'ERROR',
  INFO: 'INFO',
};

export const alertSeverityLabels: Record<AlertSeverity, string> = {
  HIGH: 'High',
  INFO: 'Info',
};

export const alertTypeLabels: Record<AlertType, string> = {
  [AlertType.BorrowCaps]: 'Borrow Cap',
  [AlertType.Borrow]: 'Borrow',
  [AlertType.Liquidations]: 'Liquidations',
  [AlertType.Liquidity]: 'Liquidity',
  [AlertType.Supply]: 'Supply',
  [AlertType.PriceChange]: 'Price Change',
  [AlertType.StablePeg]: 'Stable Peg',
  [AlertType.SupplyCaps]: 'Supply Caps',
  [AlertType.Utilizations]: 'Utilization',
  [AlertType.ValueAtRisk]: 'Value at Risk',
  [AlertType.Whale]: 'Whale Alert',
  [AlertType.BadDebt]: 'Bad Debt',
  [AlertType.DebtCeiling]: 'Debt Ceiling',
  [AlertType.HealthCheck]: 'Health Check',
};
