import { ReactNode } from 'react';

export enum TimeSpan {
  Day = 'day',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

// see: https://echarts.apache.org/v4/en/option.html#series
export type SeriesType = 'line' | 'bar' | 'area';

type BaseChartProps = {
  currency?: string
  isPercent?: boolean
  isLoading?: boolean
  yAxisLabel?: string
  dualAxis?: boolean
  yAxisLabelRight?: string
  yAxisMax?: boolean
  yAxisMin?: boolean
  showLegend?: boolean
  connectNulls?: boolean
  timeSpan?: TimeSpan
  tooltipHeaderFormatter?: (value: string | number) => ReactNode
  showPercentOfTotal?: boolean
  hideTotal?: boolean
  isStacked?: boolean
};

export type NumberChartProps = {
  series: NumberChartSeries[],
} & BaseChartProps;

export type StringChartProps = {
  series: StringChartSeries[],
} & BaseChartProps;

type BaseChartSeries = {
  id?: string,
  label?: string
  color?: string,
  type?: SeriesType,
  lineStyle?: { width: number }
  yAxisIndex?: number
  smooth?: boolean
  highlightTrends?: boolean,
  showArea?: boolean,
  animation?: boolean,
};

export type NumberChartSeries = {
  data: [number, number][]
} & BaseChartSeries;

export type StringChartSeries = {
  data: [string, number][]
} & BaseChartSeries;
