import {
  Box, ChaosTable,
} from '@chaos/ui';
import { Loader } from '@chaos/ui/loader';
import { useQuery } from '@apollo/client';
import { tableHeader, formatResults } from './market-price-table';
import { GET_MARKET_PRICE, MarketPriceResponse } from './graphql-queries';

export const MarketPriceToolComp = () => {
  const { loading, data } = useQuery<MarketPriceResponse>(GET_MARKET_PRICE);

  return (
    <Box height="100%" overflow="auto">
      {(loading) ? (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Loader />
        </Box>
      ) : (
        data && (
          <ChaosTable
            title="Oracle Monitor"
            headers={tableHeader}
            data={formatResults(data.markets)}
            pageSize={10}
            isFullHeight
            showSearch
            isInitialSortEnable
            isInitialSortDesc
            serchbarPlaceholder="Search"
            initialSortBy={4}
          />
        )
      )}
    </Box>
  );
};
