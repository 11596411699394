import { Header, RenderData } from '@chaos/types';
import {
  numberCell,
  currencyCell,
  assetCell,
  textCell,
} from '@chaos/ui/chaos-table';
import { Market } from './graphql-queries';

export const tableHeader: Header[] = [
  {
    renderType: 'TEXT',
    text: 'Asset',
    textHidden: true,
    isAutocomplete: true,
    width: '5%',
  },
  {
    renderType: 'TEXT',
    text: 'Price',
    width: '10%',
  },
  {
    renderType: 'TEXT',
    text: 'Venus Asset',
    width: '10%',
  },
  {
    renderType: 'TEXT',
    text: 'Last Update Block',
    width: '15%',
    suffix: '%',
  },
  {
    renderType: 'TEXT',
    text: 'Conversion Rate',
    width: '10%',
  },
];

export const formatResults = (
  marketPrices: Market[],
): RenderData[][] => marketPrices.filter((row) => row.inputToken.symbol !== 'CAN').map((row) => {
  const {
    inputToken,
    inputTokenPriceUSD,
    outputToken,
    outputTokenPriceUSD,
  } = row;

  const exchangeRate = outputTokenPriceUSD ? inputTokenPriceUSD / outputTokenPriceUSD : null;

  return [
    assetCell(inputToken.symbol),
    currencyCell(inputTokenPriceUSD),
    textCell(outputToken.symbol),
    textCell(inputToken.lastPriceBlockNumber.toString()),
    numberCell(exchangeRate),
  ];
});
