import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { PersistGate } from 'redux-persist/lib/integration/react';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { firebase } from './services/firebase';
import storeInstance, { persistor } from './store';

const store = storeInstance;

const rrfProps = {
  firebase,
  config: {
    userProfile: 'users',
    useFirestoreForProfile: true,
    useFirestoreForStorageMeta: true,
    allowMultipleListeners: true,
  },
  dispatch: store.dispatch,
  createFirestoreInstance,
};

dayjs.extend(utc.default);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <PersistGate persistor={persistor} loading={null}>
          <App />
        </PersistGate>
      </ReactReduxFirebaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
