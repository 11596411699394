import {
  ApolloClient, NormalizedCacheObject, InMemoryCache, ApolloProvider,
} from '@apollo/client';
import { MarketPriceToolComp } from './MarketPriceTool';

const client = new ApolloClient<NormalizedCacheObject>({
  uri: 'https://api.thegraph.com/subgraphs/id/QmZjk2Vst6AXSemJNWKXKzFHnCyvxe2CpePwkSXU7iSdhX',
  cache: new InMemoryCache(),
});

export const MarketPriceTool = () => (
  <ApolloProvider client={client}>
    <MarketPriceToolComp />
  </ApolloProvider>
);
