import {
  ApolloClient, InMemoryCache, NormalizedCacheObject, gql,
} from '@apollo/client';
import { DATA_QUERY_URL } from 'src/components/dashboard/utils/graphql';
import { protocols, Protocol } from '../types';
import { Market } from './types';

const marketsQuery = gql`query AllMarket {
  allMarkets {
    id
    name
    chain
    totalBorrowUsd
    totalCollateralUsd
    totalSupplyUsd
    borrowableAssets
    collateralableAssets
    numberOfWalletsAtRisk
    totalCollateralAtRiskUsd
  }
}`;

const getMarketsByProtocol = async (protocol: Protocol) => {
  const apolloClient = new ApolloClient<NormalizedCacheObject>({
    uri: `${DATA_QUERY_URL}query/ccar-lending`,
    cache: new InMemoryCache(),
    headers: {
      protocol,
      authkey: localStorage.getItem('authkey') || '',
    },
  });

  const res = await apolloClient.query<{ allMarkets: Market[] }>({
    query: marketsQuery,
  });

  return res.data.allMarkets;
};

export const getAllMarketsByProtocol = async () => {
  const protocolMarketsEntriesPromises = protocols.map(async (protocol) => {
    const markets = await getMarketsByProtocol(protocol);
    return [protocol, markets] as [Protocol, Market[]];
  });

  const protocolMarketsEntries = await Promise.all(protocolMarketsEntriesPromises);

  return Object.fromEntries(protocolMarketsEntries);
};
