import { DateRange } from '@mui/x-date-pickers-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRangePicker as MuiDateRangePicker, DateRangePickerProps } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import { InputLabel } from '../input-label';
import { CustomIcon } from '../custom-icon';
import { Box } from '../box';

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'This Week',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = dayjs();
      const startOfLastMonth = today.startOf('month').subtract(1, 'month');
      return [startOfLastMonth, startOfLastMonth.endOf('month')];
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
];

type Props = {
  label?: string
  placeholder?: string
  onChange: DateRangePickerProps<dayjs.Dayjs>['onAccept']
  value: DateRangePickerProps<dayjs.Dayjs>['value']
};

export const DateRangePicker = ({
  label, onChange, value, placeholder,
}: Props) => (
  <Box>
    {label && <InputLabel>{label}</InputLabel>}
    <MuiDateRangePicker
      value={value}
      onAccept={onChange}
      slots={{
        field: SingleInputDateRangeField,
      }}
      slotProps={{
        shortcuts: {
          items: shortcutsItems,
        },
        textField: {
          InputProps: {
            endAdornment: <CustomIcon size="small" icon="calendar" />,
            placeholder,
          },
        },
      }}
      calendars={1}
    />
  </Box>
);
