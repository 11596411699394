import { gql } from '@apollo/client';

export const GET_ALERTS = gql`
  fragment Alert on Alert {
    ccarClient
    id
    type
    assets
    marketId
    chain
    title
    description
    createdDate
    updatedDate
    severity
    open
    link {
      title
      href
    }
  }

  query RiskAlerts {
    riskAlerts {
      ...Alert
    }
  }
`;
