import axios from 'axios';
import qs from 'querystring';
import { v4 as uuidv4 } from 'uuid';
import {
  Asset,
  AssetInfo,
  AssetTableRow,
  BlockchainSimulation,
  EconnomicRecommendationParams,
  EconnomicRecommendationTrigger,
  Script,
  TokenPrice,
} from '@chaos/types';

const CONSTANTS = {
  headers: {
    Authorization: 'Basic <TEAM_KEY_PLACEHOLDER>',
    'Access-Control-Allow-Origin': '*',
    'X-Request-Id': uuidv4(),
  },
  engine_base_url: 'https://cloud.chaoslabs.co/',
  staging_engine_base_url: 'https://staging.chaoslabs.co/',
  dogfood_engine_base_url: 'https://dogfood.chaoslabs.co/',
  paths: {
    create_blockchain_simulation: 'create_simulation/',
    create_blockchain_agent: 'create_agent/',
    create_blockchain_scenario: 'create_scenario/',
    create_blockchain_assertion: 'create_assertion/',
    create_blockchain_observer: 'create_observers/',
    get_latest_block: 'getLatestBlock/',
    run_blockchain_simulation: 'async_simulation_v2/',
    run_python_simulation: 'economic-simulation/async_simulation/',
    cancel_blockchain_simulation: 'cancell_simulation/',
    async_simulation: 'async_simulation/',
    logs: 'simulation_logs/',
    compile: 'compile/',
    query: 'data/query',
    options: 'data/options',
    page: 'data/page',
    simulation_triggers: 'economic-simulation/chain_recommendations_triggers',
    public_simulation: 'data/public_simulations/simulation',
    chain_recommendation: 'economic-simulation/chain_recommendation',
  },
};

export function baseURL(): string {
  const hostName = window.location.hostname;
  if (hostName.includes('staging') || ['localhost', '127.0.0.1'].includes(hostName)) {
    return CONSTANTS.staging_engine_base_url;
  }
  if (hostName.startsWith('dogfood')) {
    return CONSTANTS.dogfood_engine_base_url;
  }
  return CONSTANTS.engine_base_url;
}

export function publishChainRecommendationBaseUrl(): string {
  return CONSTANTS.engine_base_url;
}

export function authHeaders(authKey: string) {
  const authHeadersObj = { ...CONSTANTS.headers };
  authHeadersObj.Authorization = `Basic ${authKey}`;
  return authHeadersObj;
}

export async function DataQueries<ReturnType = any, DataType = Record<string, unknown>>(
  query: string,
  type: 'onchain' | 'offchain',
  data?: DataType,
): Promise<ReturnType | undefined> {
  try {
    const res = await axios.post<ReturnType>(baseURL() + CONSTANTS.paths.query, {
      query,
      type,
      data,
    });

    return res.data;
  } catch (e) {
    console.log('DataQueries Error: ', e);
    return undefined;
  }
}

export async function GetPageData<ReturnType = any>(
  page: string,
  queryObj?: qs.ParsedUrlQueryInput,
): Promise<ReturnType | undefined> {
  try {
    const query = qs.stringify(queryObj);
    const url = `${baseURL()}${CONSTANTS.paths.page}/${page}${query ? `?${query}` : ''}`;
    const res = await axios.get<ReturnType>(url);

    return res.data;
  } catch (e) {
    console.log('GetPageData Error: ', e);
    return undefined;
  }
}

export async function DataQueryMap(): Promise<any> {
  try {
    const res = await axios.get(baseURL() + CONSTANTS.paths.options);
    // eslint-disable-next-line
    return res.data;
  } catch (e) {
    console.log('Simulation Error: ', e);
  }
}
export async function CreateBlockchainSimulation(
  simulation: Partial<BlockchainSimulation>,
  authKey: string,
): Promise<any> {
  try {
    const res = await axios.post(
      baseURL() + CONSTANTS.paths.create_blockchain_simulation,
      simulation,
      {
        headers: authHeaders(authKey),
      },
    );

    // eslint-disable-next-line
    return res.data;
  } catch (e) {
    console.log('Create Blockchain Simulation Error: ', e);
  }
}

export async function CreateBlockchainAgent(
  agent: Script,
  authKey: string,
): Promise<void> {
  try {
    const res = await axios.post<void>(
      baseURL() + CONSTANTS.paths.create_blockchain_agent,
      agent,
      {
        headers: authHeaders(authKey),
      },
    );

    return res.data;
  } catch (e) {
    console.log('Create Blockchain Agent Error: ', e);
  }
}

export async function CreateBlockchainScenario(
  scenario: Script,
  authKey: string,
): Promise<void> {
  try {
    const res = await axios.post<void>(
      baseURL() + CONSTANTS.paths.create_blockchain_scenario,
      scenario,
      {
        headers: authHeaders(authKey),
      },
    );

    return res.data;
  } catch (e) {
    console.log('Create Blockchain Scenario Error: ', e);
  }
}

export async function CreateBlockchainAssertion(
  assertion: Script,
  authKey: string,
): Promise<void> {
  try {
    const res = await axios.post<void>(
      baseURL() + CONSTANTS.paths.create_blockchain_assertion,
      assertion,
      {
        headers: authHeaders(authKey),
      },
    );

    return res.data;
  } catch (e) {
    console.log('Create Blockchain Assertion Error: ', e);
  }
}

export async function CreateBlockchainObserver(
  observer: Script,
  authKey: string,
): Promise<void> {
  try {
    const res = await axios.post<void>(
      baseURL() + CONSTANTS.paths.create_blockchain_observer,
      observer,
      {
        headers: authHeaders(authKey),
      },
    );

    return res.data;
  } catch (e) {
    console.log('Create Blockchain Observer Error: ', e);
  }
}

export async function RunBlockchainSimulation(
  simulationID: string,
  userID: string,
  authKey: string,
): Promise<any> {
  try {
    const res = await axios.post(
      baseURL() + CONSTANTS.paths.run_blockchain_simulation,
      {
        simulationID,
        userID,
      },
      {
        headers: authHeaders(authKey),
      },
    );

    // eslint-disable-next-line
    return res.data;
  } catch (e) {
    console.log('Run Blockchain Simulation Error: ', e);
  }
}

export async function RunPythonSimulation<DataType = Record<string, unknown>>(
  data: DataType,
  authKey: string,
) {
  try {
    const res = await axios.post(
      baseURL() + CONSTANTS.paths.run_python_simulation,
      data,
      {
        headers: authHeaders(authKey),
      },
    );

    return {
      simulationResultID: res.data as string,
    };
  } catch (e) {
    console.log('Run Python Simulation Error: ', e);

    let error = 'Error triggering simulation';
    if (axios.isAxiosError(e)) {
      error = e.response?.data as string;
    }
    throw new Error(error);
  }
}

export async function CancelBlockchainSimulation(
  resultID: string,
  userID: string,
  authKey: string,
): Promise<any> {
  try {
    const res = await axios.post(
      baseURL() + CONSTANTS.paths.cancel_blockchain_simulation,
      {
        resultID,
        userID,
      },
      {
        headers: authHeaders(authKey),
      },
    );
    return res.status === 200 ? resultID : null;
  } catch (e) {
    console.log('Cancel Blockchain Simulation Error: ', e);
  }
}

export async function Logs(
  simulationID: string,
  connectionKey: string,
  authKey: string,
): Promise<any> {
  try {
    const res = await axios.post(
      baseURL() + CONSTANTS.paths.logs,
      {
        connectionKey,
        simulationID,
        id: simulationID,
      },
      {
        headers: authHeaders(authKey),
      },
    );

    // eslint-disable-next-line
    return res.data;
  } catch (e) {
    console.log('Logs Error: ', e);
  }
}

export async function Compile(
  contractID: string,
  authKey: string,
): Promise<any> {
  try {
    const res = await axios.post(
      baseURL() + CONSTANTS.paths.compile,
      {
        contractID,
      },
      {
        headers: authHeaders(authKey),
      },
    );
    // eslint-disable-next-line
    return res.data;
  } catch (e) {
    console.log('compilation Error: ', e);
  }
}

export async function getTokenPrices(tokens: string[], currency = 'usd') {
  const res = await axios.get<TokenPrice[]>(
    `${baseURL()}${CONSTANTS.paths.page}/asset_listing?tokens=${tokens.join(',')}&currency=${currency}`,
  );
  return res.data;
}

export async function getAssetsTableData(tokens: string[], currency = 'usd') {
  const url = `${baseURL()}${CONSTANTS.paths.page}/asset_grid?tokens=${tokens.join(',')}&currency=${currency}`;
  const res = await axios.get<AssetTableRow[]>(url);

  return res.data;
}

export async function getAssetInfo(token: Asset, currency = 'usd') {
  const res = await axios.get<AssetInfo>(
    `${baseURL()}${CONSTANTS.paths.page}/asset_info?token=${token.geckoId || token.id}${
      token.address ? `&address=${token.address}` : ''
    }&messariId=${token.messariId || token.id}&currency=${currency}${
      token.proposalId ? `&proposalId=${token.proposalId}` : ''
    }${
      token.onChainProposalId ? `&onChainProposalId=${token.onChainProposalId}` : ''
    }`,
  );
  return res.data;
}

export async function getLatestBlock(authKey: string, chain: 'ethereum' | 'avalanche' | 'polygon'): Promise<string> {
  const url = `${baseURL()}${CONSTANTS.paths.get_latest_block}?chain=${chain}`;
  const res = await axios.get<string>(url, {
    headers: authHeaders(authKey || ''),
  });
  return res.data;
}

export async function getSimulationParams(
  authKey: string,
  id: string,
): Promise<EconnomicRecommendationParams> {
  const url = `${baseURL()}${CONSTANTS.paths.simulation_triggers}/${id}`;
  const res = await axios.get<EconnomicRecommendationParams>(url, {
    headers: authHeaders(authKey || ''),
  });
  return res.data;
}

export async function publishChainRecommendation(
  authKey: string,
  protocol: string,
  simulationId: string,
  args: {
    id: string,
    publishWithRecommendation: boolean,
    overrideArguments?: { args: Record<string, number> }
  }[],
  state: 'Pending' | 'Published',
): Promise<void> {
  const url = `${baseURL()}${CONSTANTS.paths.public_simulation}?simulationId=${simulationId}&type=${protocol.replace(protocol[0], protocol[0].toUpperCase())}ParamRecommendations`;
  await axios.put(url, { simulationResultsInfo: args, state }, { headers: authHeaders(authKey || '') });
}

export async function postChainRecommendation(
  authKey: string,
  protocol: string,
  marketId: string,
  chainName: string,
  assets?: string[],
): Promise<void> {
  const url = `${baseURL()}${CONSTANTS.paths.chain_recommendation}`;
  await axios.post(url, {
    protocol,
    marketId,
    chainName,
    overrideAssetSymbols: assets,
    modules: [
      {
        name: 'LT_LB',
        data: { step: 'run_recommended_permutation' },
      },
    ],
  }, { headers: authHeaders(authKey || '') });
}

export async function getSimulationTriggers(
  authKey: string,
  pageSize = 30,
  lastId?: string,
): Promise<{
    last_id?: string,
    recommendations:EconnomicRecommendationTrigger[]
  }> {
  const url = `${baseURL()}${CONSTANTS.paths.simulation_triggers}`;
  const res = await axios.get<{
    lastId?: string,
    recommendations:EconnomicRecommendationTrigger[]
  }>(url, {
    params: { lastId, pageSize },
    headers: authHeaders(authKey || ''),
  });

  return res.data;
}
