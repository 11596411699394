import { Header, RenderData } from '@chaos/types';
import { Box, ChaosTable, EmptyStateProps } from '@chaos/ui';
import { Alert, AlertFilters } from '@chaos/ui/alert-filters';
import { formatDateAndTime } from '@chaos/ui/utils/formatters';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { wrappedSymbolToIconSymbol } from '@chaos/ui/utils/icon-helper';
import { useQuery } from '@apollo/client';
import { TitleCell } from './components';
import { alertSeverityLabels, alertSeverityStatus, alertTypeLabels } from './constants';
import { AlertDrawer } from './components/alert-drawer';

import {
  AlertSeverity, AlertType, Chain, RiskAlertsQuery,
} from './generated';
import { getChainDisplayName } from './utils';
import { GET_ALERTS } from './alerts-query';

const alertsHeaders: Header[] = [
  {
    renderType: 'TEXT',
    text: 'Client',
  },
  {
    renderType: 'TEXT',
    text: 'Risk Type',
    width: '150px',
  },
  {
    renderType: 'TEXT',
    text: 'Severity',
    width: '90px',
  },
  {
    renderType: 'TEXT',
    text: 'Name',
  },
  {
    renderType: 'TEXT',
    text: 'Asset',
    width: '90px',
  },
  {
    renderType: 'TEXT',
    text: 'Chain',
  },
  {
    renderType: 'TEXT',
    text: 'Date',
  },
];

const mapAlerts = (alert: Alert): RenderData[] => {
  const formattedCreatedDate = `${formatDateAndTime(dayjs.unix(alert.createdDate).toDate())}`;
  const severityStatus = alertSeverityStatus[alert.severity as AlertSeverity];
  const severityLabel = alertSeverityLabels[alert.severity as AlertSeverity];

  return [
    {
      renderType: 'TEXT',
      text: alert.ccarClient.toLowerCase().replaceAll('_', ' '),
      textTransform: 'capitalize',
      cryptoIcon: alert.ccarClient.toLowerCase().split('_')[0],
    },
    {
      renderType: 'TEXT',
      text: alert.type ? alertTypeLabels[alert.type as AlertType] : '',
    },
    {
      text: severityLabel,
      renderType: 'LABELS',
      status: severityStatus,
    },
    {
      renderType: 'CUSTOM',
      text: alert.title,
      component: <TitleCell title={alert.title} isOpen={alert.open} />,
    },
    {
      renderType: 'TEXT',
      cryptoIcon: alert.assets ? wrappedSymbolToIconSymbol(alert.assets[0]) : '',
      text: alert.assets ? alert.assets[0] : '',
    },
    {
      renderType: 'TEXT',
      text: alert.chain ? getChainDisplayName(alert.chain as Chain) : '',
      cryptoIcon: alert.chain?.toLowerCase(),
    },
    {
      renderType: 'TEXT',
      text: formattedCreatedDate,
      value: alert.createdDate,
    },
  ];
};

type AlertsTableProps = {
  data: Alert[]
  loading: boolean
  emptyState: EmptyStateProps
};

const AlertsTable = ({ data, loading, emptyState }: AlertsTableProps) => (
  <ChaosTable
    pageSize={10}
    isFullHeight
    isLoading={loading}
    isSettingsHidden
    title="Alerts"
    headers={alertsHeaders}
    data={data.map(mapAlerts)}
    resetPagination={data.map((d) => d.id).join('-')}
    isFilterHidden
    emptyState={emptyState}
    rowHref={(rowId) => {
      const row = data[rowId];
      if (!row) {
        return '';
      }
      const { id } = row;
      if (!id) {
        return '';
      }
      return `${String(id)}`;
    }}
  />
);

export const AlertsHub = () => {
  const { data, loading } = useQuery<RiskAlertsQuery>(GET_ALERTS);
  const alertAssets: string[] | undefined = [];
  const alertChains: string[] | undefined = [];
  const alertTypes = Object.keys(AlertType) as (keyof typeof AlertType)[];
  const [tableData, setTableData] = useState<Alert[]>([]);
  const emptyState = {
    icon: 'warning',
    title: 'No Alerts Found',
  };

  const applyFilters = useCallback((alerts: Alert[]) => {
    setTableData(alerts);
  }, []);

  useEffect(() => {
    if (data?.riskAlerts) {
      applyFilters(data.riskAlerts as Alert[]);
    }
  }, [data?.riskAlerts, applyFilters]);

  return (
    <Box height="100%" display="flex" flexDirection="column" gap={[2, 3]}>
      <AlertFilters
        onChange={applyFilters}
        assets={alertAssets}
        chains={alertChains}
        types={alertTypes.map((t) => AlertType[t])}
        alertSeverities={[alertSeverityLabels.HIGH, alertSeverityLabels.INFO]}
        alertTypeLabels={(s) => alertTypeLabels[s as AlertType]}
        alerts={data?.riskAlerts as Alert[] || []}
      />
      <AlertsTable data={tableData} loading={loading} emptyState={emptyState} />
      <Routes>
        <Route
          path="/alert-hub"
          element={<AlertDrawer />}
        />
      </Routes>
    </Box>
  );
};

export default AlertsHub;
