import { authHeaders, baseURL, publishChainRecommendationBaseUrl } from 'src/services/engine';
import axios from 'axios';
import {
  ModelRun,
  ModelRunResults,
  PromoteModelResultsRequest,
  PromoteModelResultsResponse,
  PublishModelRunRequest,
  PublishModelRunResponse,
  TriggerChainRecommendationRequest,
} from './types';

const economicBaseUrl = `${baseURL()}economic-simulation/recommendations`;
const publishBaseUrl = `${publishChainRecommendationBaseUrl()}data/public_simulations/v2/recommendations`;

export const getModelRunsPage = async (authKey: string, pageSize = 30, lastId?: string) => {
  const url = `${economicBaseUrl}/results`;

  const res = await axios.get<{ modelResults: ModelRun[] }>(url, {
    params: { lastId, pageSize },
    headers: authHeaders(authKey || ''),
  });

  return res.data.modelResults;
};

export const getModelRunResults = async (id: string, authKey: string) => {
  const url = `${economicBaseUrl}/result/${id}`;

  const res = await axios.get<ModelRunResults>(url, {
    headers: authHeaders(authKey || ''),
  });

  return res.data;
};

export const publishModelRun = async (
  publishModelRequest: PublishModelRunRequest,
  authKey: string,
) => {
  const res = await axios.put<PublishModelRunResponse>(publishBaseUrl, {
    models: [publishModelRequest],
    state: 'Pending',
  }, {
    headers: authHeaders(authKey || ''),
  });

  return res.data;
};

export const promoteModelResults = async (
  promoteModelResultRequest: PromoteModelResultsRequest,
  authKey: string,
) => {
  const res = await axios.put<PromoteModelResultsResponse>(
    `${publishBaseUrl}/promote`,
    { recommendations: promoteModelResultRequest },
    {
      headers: authHeaders(authKey || ''),
    },
  );

  return res.data;
};

export const postChainRecommendation = async (
  authKey: string,
  chainRecommendationRequest: TriggerChainRecommendationRequest,
): Promise<string> => {
  const url = `${baseURL()}economic-simulation/chain_recommendation`;
  const res = await axios.post<string>(url, {
    ...chainRecommendationRequest,
    modules: [
      {
        name: 'LT_LB',
        data: { step: 'run_recommended_permutation' },
      },
    ],
  }, { headers: authHeaders(authKey || '') });

  return res.data;
};
