import {
  CryptoIcon, Drawer, Grid, Typography,
} from '@chaos/ui';
import { formatDateAndTime } from '@chaos/ui/utils/formatters';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { TagChip } from '@chaos/ui/tag-chip';
import { wrappedSymbolToIconSymbol } from '@chaos/ui/utils/icon-helper';
import { AlertFragment, AlertSeverity, useRiskAlertsQuery } from '../generated';
import { alertSeverityLabels } from '../constants';
import { getChainDisplayName } from '../utils';

export const AlertDrawer = () => {
  const { alertId = '' } = useParams<'alertId'>();
  const { data, loading } = useRiskAlertsQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const alert: AlertFragment | undefined = data?.riskAlerts.find((a) => a.id === alertId);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (loading) {
      return;
    }
    setOpen(true);
  }, [loading]);

  if (!alert) {
    return null;
  }

  let severityColor;
  const severityLabel = alertSeverityLabels[alert.severity];
  switch (alert.severity) {
    case AlertSeverity.High:
      severityColor = 'error.opacity50';
      break;
    default:
      severityColor = 'light.opacity50';
      break;
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      SlideProps={{
        onExited() {
          navigate(location.pathname.replace(`/${alertId}`, ''));
        },
      }}
      onClose={() => {
        setOpen(false);
      }}
      title={alert.title}
    >
      <Grid container spacing={[2, 3]}>
        <Grid item xs={6} md={4}>
          <Typography color="almostWhite.main">Date</Typography>
          <Typography>{formatDateAndTime(dayjs.unix(alert.createdDate).toDate())}</Typography>
        </Grid>
        <Grid item xs={6} md={4}>
          <Typography color="almostWhite.main">Severity</Typography>
          <Typography>
            <TagChip label={severityLabel} sx={{ bgcolor: severityColor }} />
          </Typography>
        </Grid>
        {alert.chain && (
          <Grid item xs={6} md={4}>
            <Typography color="almostWhite.main">Chains</Typography>
            <Typography>
              <CryptoIcon icon={alert.chain?.toLowerCase()} sx={{ pr: 0.5 }} />
              {getChainDisplayName(alert.chain)}
            </Typography>
          </Grid>
        )}
        {alert.assets && (
          <Grid item xs={6} md={4}>
            <Typography color="almostWhite.main">Asset</Typography>
            {alert.assets?.map((asset) => (
              <Typography key={asset}>
                <CryptoIcon icon={wrappedSymbolToIconSymbol(asset)} sx={{ pr: 0.5 }} />
                {asset}
              </Typography>
            ))}
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography color="almostWhite.main">Description</Typography>
          <Typography>{alert.description}</Typography>
        </Grid>
      </Grid>
    </Drawer>
  );
};
