import {
  Link, Box, Chip, Typography, Tooltip, CustomIcon,
} from '@chaos/ui';

type TitleCellProps = {
  title: string
  isOpen?: boolean
};

export const TitleCell = ({ title, isOpen = false }: TitleCellProps) => (
  <Typography sx={{
    gap: 1, display: 'flex', alignItems: 'center', width: '100%',
  }}
  >
    <Tooltip title={title}>
      <Box component="span" sx={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {title}
      </Box>
    </Tooltip>
    {isOpen && <Chip size="small" color="error" label="Open" />}
  </Typography>
);

type LinkCellProps = {
  href?: string
  title?: string
};

export const LinkCell = ({ title, href }: LinkCellProps) => {
  if (!href) {
    return null;
  }
  return (
    <Link
      href={href}
      target="_blank"
      color="white.main"
      sx={{
        gap: 1,
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: 14,
      }}
    >
      <CustomIcon icon="external-link" size="small" />
      {title}
    </Link>
  );
};
