import { Box } from '../box';
import { Typography } from '../typography';
import { CustomIcon } from '../custom-icon';

export type EmptyStateProps = {
  height?: number,
  icon?: string
  title?: string
  children?: React.ReactNode
};

export const EmptyState = ({
  height = 180, icon, title, children,
}: EmptyStateProps) => (
  <Box height={height} flexGrow={1} display="flex" alignItems="center" justifyContent="center" gap={1} color="almostWhite.main">
    {icon && <CustomIcon icon={icon} sx={{ svg: { color: 'currentColor' }, path: { fill: 'currentColor' } }} />}
    {title && <Typography fontWeight={600}>{title}</Typography>}
    {children}
  </Box>
);
