import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import { Box } from '../box';
import { Typography } from '../typography';
import { IconButton } from '../icon-button';
import { CustomIcon } from '../custom-icon';

type Props = {
  title: string
  subtitle?: React.ReactNode | string;
} & DrawerProps;

export const Drawer = ({
  title, subtitle, children, onClose = () => {}, ...muiDrawerProps
}: Props) => (
  <MuiDrawer anchor="right" onBackdropClick={(e) => onClose(e, 'backdropClick')} {...muiDrawerProps}>
    <Box sx={{
      p: [2, 4],
      display: 'flex',
      justifyContent: 'space-between',
    }}
    >
      <Box>
        {title && <Typography variant="h3">{title}</Typography>}
        {subtitle && (typeof subtitle === 'string' ? <Typography variant="caption">{subtitle}</Typography> : subtitle)}
      </Box>
      <Box sx={{ height: '32px', display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={(e) => onClose(e, 'backdropClick')}>
          <CustomIcon icon="close" />
        </IconButton>
      </Box>
    </Box>
    <Box data-testid="drawer-content" sx={{ px: [2, 4] }}>{children}</Box>
  </MuiDrawer>
);
