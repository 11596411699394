import { formatAmount } from '../utils/formatters';
import { TimeSpan } from './types';

const formatCurrency = (v: number, notation: 'compact' | 'standard') => formatAmount(Number(v), { currency: 'USD', notation });
const formatPercent = (v: number) => formatAmount(Number(v), { isPercent: true });

export const formatEChartValue = (isPercent = false, currency = 'USD', notation: 'compact' | 'standard' = 'compact') => (v: number) => {
  if (isPercent) {
    return formatPercent(v);
  }
  if (currency.length) {
    return formatCurrency(v, notation);
  }
  return formatAmount(Number(v), { notation });
};

export const xAxisDateTemplateByTimeSpan = (timeSpan?: TimeSpan) => {
  switch (timeSpan) {
    case TimeSpan.Day:
      return 'h A';
    default:
      return 'DD/MM';
  }
};

export const tooltipDateTemplateByTimeSpan = (timeSpan?: TimeSpan) => {
  switch (timeSpan) {
    case TimeSpan.Day:
    case TimeSpan.Week:
      return 'DD/MM/YYYY [at] h A';
    default:
      return 'DD/MM/YYYY';
  }
};

export const daysAgoByTimeSpan: Record<TimeSpan, number> = {
  [TimeSpan.Day]: 1,
  [TimeSpan.Week]: 7,
  [TimeSpan.Month]: 30,
  [TimeSpan.Quarter]: 90,
  [TimeSpan.Year]: 365,
};
