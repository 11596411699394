import {
  gql,
} from '@apollo/client';

export const GET_MARKET_PRICE = gql`
  fragment TokenFragment on Token {
    id
    name
    symbol
    lastPriceUSD
    lastPriceBlockNumber
  }

  query MarketPrices {
    markets {
      name
      inputToken {
        ...TokenFragment
      }
      inputTokenPriceUSD
      outputToken {
        ...TokenFragment
      }
      outputTokenPriceUSD
    }
  }
`;
