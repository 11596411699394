import { PropsWithChildren } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { dataQueryURL } from '../utils/data-query-url';

export const DATA_QUERY_URL = dataQueryURL();

export const ChaosApolloProvider = ({ children }: PropsWithChildren<object>) => {
  const apolloClient = new ApolloClient<NormalizedCacheObject>({
    uri: `${DATA_QUERY_URL}query/alerts`,
    cache: new InMemoryCache(),
    headers: { authkey: localStorage.getItem('authkey') || '' },
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};
