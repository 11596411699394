import {
  CustomMultiReactSelect, DateRangePicker, Grid, ToggleButtonGroup, ToggleButton, InputLabel,
} from '@chaos/ui';
import dayjs from 'dayjs';
import { useState } from 'react';
import { wrappedSymbolToIconSymbol } from '@chaos/ui/utils/icon-helper';

export type Alert = {
  ccarClient: string,
  id: string,
  createdDate: number,
  severity: string,
  type: string,
  assets?: string[],
  chain: string,
  title: string,
  description: string,
  link?: { title: string, href: string },
  open?: boolean
};

export type AlertFiltersValue = {
  types?: string[]
  fromDate?: number | null
  toDate?: number | null
  severity?: string[]
  assets?: string[]
  chains?: string[]
};

type Props = {
  alerts: Alert[],
  onChange: (alerts: Alert[]) => void
  types?: string[]
  assets?: string[]
  chains?: string[]
  alertTypeLabels: (s: string) => string
  alertSeverities: string[]
};

export const AlertFilters = ({
  onChange, types = [], assets = [], chains = [], alertTypeLabels, alertSeverities, alerts,
}: Props) => {
  const [value, setValue] = useState<AlertFiltersValue>({});
  const fromDate = value.fromDate ? dayjs(value.fromDate * 1000) : null;
  const toDate = value.toDate ? dayjs(value.toDate * 1000) : null;

  const handleChange = (e: AlertFiltersValue) => {
    setValue(e);
    const newAlerts = alerts
      .filter((alert) => {
        if (e.types?.length && (!alert.type || !e.types.includes(alert.type))) {
          return false;
        } if (e.assets?.length
            && !e.assets.filter((asset) => alert.assets?.includes(asset)).length) {
          return false;
        } if (e.chains?.length && (!alert.chain || !e.chains.includes(alert.chain))) {
          return false;
        } if (e.severity?.length
          && !e.severity.find((s) => s.toLowerCase() === alert.severity.toLowerCase())) {
          return false;
        } if ((e.fromDate && e.toDate)
            && (alert.createdDate < e.fromDate || alert.createdDate > e.toDate)) {
          return false;
        }

        return true;
      });
    setTimeout(() => {
      onChange(newAlerts);
    }, 0);
  };

  return (
    <Grid spacing={[1, 2]} container>
      {!!types.length && (
        <Grid xs={12} md={6} lg={4} item>
          <CustomMultiReactSelect
            controlShouldRenderValue
            label="Risk Types"
            placeholder="Select Risk Types"
            value={value.types?.map((t) => ({
              value: t,
              label: alertTypeLabels(t),
            }))}
            options={types.map((k) => ({
              value: k,
              label: alertTypeLabels(k),
            }))}
            onChange={(e) => {
              handleChange({
                ...value,
                types: e.map((a) => a.value),
              });
            }}
          />
        </Grid>
      )}
      {!!assets.length && (
        <Grid xs={12} md={6} lg={4} item>
          <CustomMultiReactSelect
            controlShouldRenderValue
            label="Involved Assets"
            placeholder="Select Involved Assets"
            options={assets.map((asset) => ({
              value: asset,
              label: asset,
              icon: wrappedSymbolToIconSymbol(asset),
            }))}
            onChange={(e) => {
              handleChange({
                ...value,
                assets: e.map((a) => a.value),
              });
            }}
          />
        </Grid>
      )}
      {!!chains.length && (
        <Grid xs={12} md={6} lg={4} item>
          <CustomMultiReactSelect
            controlShouldRenderValue
            label="Chains"
            placeholder="Select Chains"
            options={chains.map((chain) => ({
              value: chain,
              label: chain,
              icon: chain.toLowerCase(),
            }))}
            onChange={(e) => {
              handleChange({
                ...value,
                chains: e.map((a) => a.value),
              });
            }}
          />
        </Grid>
      )}
      <Grid xs={12} md={6} lg={6} item>
        <InputLabel>Severity</InputLabel>
        <ToggleButtonGroup
          fullWidth
          value={value.severity}
          onChange={(_, v) => {
            handleChange({
              ...value,
              severity: v as string[],
            });
          }}
        >
          {alertSeverities.map((k) => <ToggleButton key={k} value={k}>{k}</ToggleButton>)}
        </ToggleButtonGroup>
      </Grid>
      <Grid xs={12} md={6} lg={6} item>
        <DateRangePicker
          label="Time Range"
          placeholder="Select Date Range"
          value={[fromDate, toDate]}
          onChange={(e) => {
            const newFromDate = e[0] && e[0].unix();
            const newToDate = e[1] && e[1].unix();

            handleChange({
              ...value,
              fromDate: newFromDate,
              toDate: newToDate,
            });
          }}
        />
      </Grid>
    </Grid>
  );
};
