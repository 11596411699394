/* eslint-disable no-bitwise */
import MersenneTwister from 'mersenne-twister';
import { useEffect, useRef } from 'react';
import { Box } from '../box';

const allColors = [
  '#FFA318',
  '#24B3D0',
  '#2DB574',
  '#DE4469',
  '#7146A0',
  '#0366C1',
  '#9a22d2',
  '#0e55bf',
];

const svgns = 'http://www.w3.org/2000/svg';
const shapeCount = 4;

function genColor(generator: MersenneTwister, colors: string[]) {
  const idx = Math.floor(colors.length * generator.random());
  const color = colors.splice(idx, 1)[0];
  return color;
}

function genShape(
  generator: MersenneTwister,
  remainingColors: string[],
  diameter: number,
  i: number,
  total: number,
  svg: SVGSVGElement,
) {
  const center = diameter / 2;

  const shape = document.createElementNS(svgns, 'rect');
  shape.setAttributeNS(null, 'x', '0');
  shape.setAttributeNS(null, 'y', '0');
  shape.setAttributeNS(null, 'width', diameter.toString());
  shape.setAttributeNS(null, 'height', diameter.toString());

  const firstRot = generator.random();
  const angle = Math.PI * 2 * firstRot;
  const velocity = ((diameter / total) * generator.random()) + ((i * diameter) / total);

  const tx = (Math.cos(angle) * velocity);
  const ty = (Math.sin(angle) * velocity);

  const translate = `translate(${tx} ${ty})`;

  // Third random is a shape rotation on top of all of that.
  const secondRot = generator.random();
  const rot = (firstRot * 360) + secondRot * 180;
  const rotate = `rotate(${rot.toFixed(1)} ${center} ${center})`;
  const transform = `${translate} ${rotate}`;
  shape.setAttributeNS(null, 'transform', transform);
  const fill = genColor(generator, remainingColors);
  shape.setAttributeNS(null, 'fill', fill);

  svg.appendChild(shape);
}

function generateIdenticon(diameter: number, seed: number) {
  const generator = new MersenneTwister(seed);
  const remainingColors = [...allColors];

  const svg = document.createElementNS(svgns, 'svg');
  svg.setAttributeNS(null, 'x', '0');
  svg.setAttributeNS(null, 'y', '0');
  svg.setAttributeNS(null, 'width', diameter.toString());
  svg.setAttributeNS(null, 'height', diameter.toString());

  for (let i = 0; i < shapeCount - 1; i += 1) {
    genShape(generator, remainingColors, diameter, i, shapeCount - 1, svg);
  }

  return svg;
}

const cyrb53 = (str: string, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed; let
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i += 1) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const Identicon = ({ diameter, seed }: { diameter: number, seed: string }) => {
  const svg = useRef<HTMLElement>(null);
  useEffect(() => {
    if (svg.current) {
      svg.current.replaceChildren(generateIdenticon(diameter, cyrb53(seed)));
    }
  }, [seed, diameter]);

  return <Box ref={svg} display="flex" borderRadius={diameter} overflow="hidden" flexShrink={0} />;
};
